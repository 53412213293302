import { construct } from "../../../core/services/construct.helper";
import { DependencyInjector } from "../../../core/services/dependency-injector";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  EditableType,
  NotSerializable,
  Serializable,
  Title
} from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { ValidationContext } from "../../../meta/models/validation-context";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";

const ID_PREFIX = "IFRAME_";

export enum TextAlignment {
  None = "None",
  Left = "Left",
  Center = "Center",
  Right = "Right"
}

export enum Overflow {
  Auto = "Auto",
  Scroll = "Scroll",
  Visible = "Visible",
  Hidden = "Hidden"
}

export enum Clear {
  None = "None",
  Left = "Left",
  Both = "Both",
  Right = "Right"
}

export enum Dock {
  None = "None",
  Left = "Left",
  Right = "Right"
}
// @dynamic
@EditableType({ fullName: "IframeViewConfig" })
export class IframeViewConfig extends BaseViewConfigDto {
  typeName = "IframeViewConfig";

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Src,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  src!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Id,
    editorType: EditorType.TextBox,
    validationFunction: validateId
  })
  @Serializable("")
  id!: string;

  @Title()
  @NotSerializable()
  title?: string;

  @NotSerializable()
  displayFormat!: string;

  @NotSerializable()
  titleFormat!: string;

  @NotSerializable()
  backgroundImage!: string;

  @ConfigurableEnum({
    enumSource: TextAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TextAlignment
  })
  @Serializable(TextAlignment.None)
  textAlignment!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.KeepTogether,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  keepTogether!: boolean;

  @ConfigurableEnum({
    enumSource: Overflow,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Overflow
  })
  @Serializable(Overflow.Auto)
  overflow!: string;

  @ConfigurableEnum({
    enumSource: Clear,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Clear
  })
  @Serializable(Clear.None)
  clear!: string;

  @ConfigurableEnum({
    enumSource: Dock,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Dock
  })
  @Serializable(Dock.None)
  dock!: string;

  constructor(viewConfigDto: DeepPartial<IframeViewConfig> = {}) {
    super();
    if (isNotDefined(viewConfigDto.id)) {
      viewConfigDto.id = `${ID_PREFIX}${
        DependencyInjector.get(ComponentStateSelector).getComponentCount() + 1
      }`;
    }
    const defaultOverrides: Partial<IframeViewConfig> = {
      hasRuntimeData: false,
      supportsInteraction: false,
      size: getDefaultSize(),
      title: "IFrame"
    };

    construct(this, viewConfigDto, "IframeViewConfig", defaultOverrides);
  }
}
function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("500", "300");
}

function validateId(value: any, validationContext?: ValidationContext): boolean {
  const componentStateSelector = DependencyInjector.get(ComponentStateSelector);
  return isNotDefined(
    componentStateSelector
      .getAllAsArray()
      .filter((component) => component.id !== validationContext?.itemOwner.id)
      .find((component) => (component.view as IframeViewConfig).id === value)
  );
}
