import { ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { SeriesOptionsType } from "highcharts";
import HC_Histogram from "highcharts/modules/histogram-bellcurve";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import { getConnectorViewId } from "projects/ui-core/src/lib/data-connectivity/helpers/connector-view-id.helper";
import { refreshLoadingIndicator } from "projects/ui-core/src/lib/elements/helpers/create-loading-indicator.helper";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "projects/ui-core/src/lib/elements/services/highcharts/base-highcharts-options.helper";
import { Observable, of } from "rxjs";
import {
  BaseComponent,
  ChartComponent,
  ComponentConstructorParams,
  ConnectorRoles,
  DataConnectorDto,
  Dictionary,
  EditableType,
  LayoutBuilder,
  MaxConnectors,
  Maybe,
  NumberOfDataPointsToRequest,
  SignalDataSourceDto,
  View,
  isDefined
} from "ui-core";
import { ClientDto, Query } from "../../models/api/query";
import { QueryService } from "../../services/api/query.service";
import {
  AggregatedVibrationData,
  HeatmapTile,
  TrendResultDto,
  VibrationData,
  VibrationDateRange,
  VibrationSpeed
} from "../../types";
import { RdsComponentCategory } from "../rds-component-category";
import { Roles } from "./roles";
import { VibrationChart } from "./vibration-chart.interface";
import { VibrationChartViewConfig } from "./view.config";

interface IRectangle {
  xMin: number;
  xMax: number;
  sum: number;
  points: HeatmapTile[];
};

const DATA_POINTS_TO_REQUEST = 100_000;
@ConnectorRoles(Roles)
@MaxConnectors(20)
@Component({
  selector: "rds-vibration-chart",
  templateUrl: "./vibration-chart.component.html",
  styleUrls: ["./vibration-chart.component.scss"],
  providers: [
    {
      provide: BaseComponent,
      useExisting: VibrationChartComponent
    }
  ]
})
@LayoutBuilder(
  RdsComponentCategory.ABB,
  "VibrationChartComponent",
  "Target_value_range",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.VibrationChart
)
@EditableType({ fullName: "VibrationChartComponent", title: "vibration-chart" })
@NumberOfDataPointsToRequest(() => DATA_POINTS_TO_REQUEST)
export class VibrationChartComponent extends ChartComponent {
  private viewConfig!: VibrationChartViewConfig;

  private readonly VIBRATION_TAG: string = "VIB_FOURIER_BUCKETS";
  private readonly VIBRATION_BUCKET_REGEX = /\d/;

  private readonly HEATMAP_X_AXIS_MIN = 0;
  private readonly HEATMAP_X_AXIS_MAX = 301;

  private series: HeatmapTile[] = [];

  private aggregatedVibrationData?: Map<number, AggregatedVibrationData>;

  private negativeValues: boolean = false;

  private top6BottomPoints: HeatmapTile[] = [];
  private top6BottomXCoordinates: number[] = [];

  // Histogram
  private readonly HISTOGRAM_SIGNAL_PTYPE = "PRI";
  protected histogramChartObjects: Highcharts.Chart[] = [];
  protected histogramChartOptions: Highcharts.Options[] = [{}];
  protected shouldShowHistograms = false;
  protected shouldShowClickMessage = true;
  protected loadingSpinner: Maybe<HTMLElement>;
  @ViewChild("spinnerContainer") spinnerContainer: Maybe<ElementRef>;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    cdr: ChangeDetectorRef,
    private queryService: QueryService
  ) {
    super(params, hostElementRef, cdr);
    HC_Histogram(this.Highcharts);
  }

  @View(VibrationChartViewConfig)
  public get view(): VibrationChartViewConfig {
    return this.currentState.view as VibrationChartViewConfig;
  }

  private sortBucketsAndSpeeds(speeds: VibrationSpeed[], buckets: number[][]): VibrationData {
    let indexes = speeds.map((_, index) => index);
    indexes.sort((a, b) => speeds[a].value - speeds[b].value);

    const sortedSpeeds = speeds.sort((a, b) => a.value - b.value);
    const sortedBuckets = indexes.map((index) => buckets[index]);

    return {
      speeds: sortedSpeeds,
      buckets: sortedBuckets
    };
  }

  private getHeatmapTiles(
    limitPoints: number[],
    calculatedBuckets: Map<number, AggregatedVibrationData>
  ): HeatmapTile[] {
    const tiles: HeatmapTile[] = [];
    const minY = calculatedBuckets.entries().next().value[0];
    const maxY = Array.from(calculatedBuckets.entries()).pop()![0];

    for (let [speed, bucketValues] of calculatedBuckets) {
      bucketValues.buckets.forEach((value: number, index: number) => {
        tiles.push([limitPoints[index], speed * 10, value]);

        const currFrequency = limitPoints[index];
        const prevFrequency = limitPoints[index - 1];

        if (currFrequency - prevFrequency > 1) {
          const diff = currFrequency - prevFrequency;

          for (let fillIndex = 1; fillIndex <= diff; fillIndex++) {
            tiles.push([prevFrequency + fillIndex, speed * 10, value]);
          }
        }
      });
    }

    const rectangleWidth = 15;

    const rectangles: number[] = [];
    const maxX = 301;
    let tilesCopy = tiles.map(tile => [...tile] as [number, number, number]);
    tilesCopy = tilesCopy.filter((tileCopy) => tileCopy[1] > 86);

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const rectanglesCopy = rectangles;
    const top6Rectangles: number[][] = [];

    const maxValue = Math.max(...rectangles);
    const maxIndex = rectangles.indexOf(maxValue);

    top6Rectangles.push([maxIndex, maxValue]);

    tilesCopy.map((tile) => {
      if (tile[0] >= top6Rectangles[0][0] && tile[0] < top6Rectangles[0][0] + rectangleWidth) {
        tile[2] = 0;
      }

      return tile;
    });

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const maxValue1 = Math.max(...rectanglesCopy);
    const maxIndex1 = rectangles.indexOf(maxValue1);
    top6Rectangles.push([maxIndex1, maxValue1]);
    const slippage = 10;

    tilesCopy.map((tile) => {
      if (tile[0] >= top6Rectangles[1][0] - slippage && tile[0] < top6Rectangles[1][0] + slippage + rectangleWidth) {
        tile[2] = 0;
      }

      return tile;
    });

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const maxValue2 = Math.max(...rectanglesCopy);
    const maxIndex2 = rectangles.indexOf(maxValue2);
    top6Rectangles.push([maxIndex2, maxValue2]);

    tilesCopy.map((tile) => {
      if (tile[0] >= top6Rectangles[2][0] - slippage && tile[0] < top6Rectangles[2][0] + slippage + rectangleWidth) {
        tile[2] = 0;
      }

      return tile;
    });

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const maxValue3 = Math.max(...rectanglesCopy);
    const maxIndex3 = rectangles.indexOf(maxValue3);
    top6Rectangles.push([maxIndex3, maxValue3]);

    tilesCopy.map((tile) => {
      if (tile[0] >= top6Rectangles[3][0] - slippage && tile[0] < top6Rectangles[3][0] + slippage + rectangleWidth) {
        tile[2] = 0;
      }

      return tile;
    });

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const maxValue4 = Math.max(...rectanglesCopy);
    const maxIndex4 = rectangles.indexOf(maxValue4);
    top6Rectangles.push([maxIndex4, maxValue4]);

    tilesCopy.map((tile) => {
      if (tile[0] >= top6Rectangles[4][0] - slippage && tile[0] < top6Rectangles[4][0] + slippage + rectangleWidth) {
        tile[2] = 0;
      }

      return tile;
    });

    for (let x = 0; x < maxX - rectangleWidth; x++) {
      rectangles.push(0);
      let numberOfTilesInRectangle = 0;

      tilesCopy.forEach((tile: HeatmapTile) => {
        if (tile[0] >= x && tile[0] < x + rectangleWidth) {
          rectangles[x] += tile[2];
          numberOfTilesInRectangle++;
        }
      });

      rectangles[x] = rectangles[x] / numberOfTilesInRectangle;
    }

    const maxValue5 = Math.max(...rectanglesCopy);
    const maxIndex5 = rectangles.indexOf(maxValue5);
    top6Rectangles.push([maxIndex5, maxValue5]);
    
    const highestSpeed = Math.max(...calculatedBuckets.keys()) * 10;
    const top6Areas = top6Rectangles.map(r => r[0]).sort((a, b) => a - b);
    const points = this.getMostHeatedPointsForYRow(tiles, top6Areas, highestSpeed, (slippage * 2));

    this.top6BottomPoints = points;

    return tiles;
  }

  private getMostHeatedPointsForYRow(
    tiles: HeatmapTile[],
    areas: number[],
    y: number,
    offset: number
  ): HeatmapTile[] {
    const result: HeatmapTile[] = [];
    const maxYTiles = tiles.filter(tile => tile[1] === y);

    areas.forEach((area: number) => {
      const range = maxYTiles.filter((tile: HeatmapTile) => tile[0] >= area && tile[0] <= area + offset);
      const maxInRange = range.reduce((max: HeatmapTile, tile: HeatmapTile) => tile[2] > max[2] ? tile : max);

      result.push(maxInRange);
    });

    return result;
  }

  private calculateBucketsWithCommonSpeed(
    groupedBuckets: Map<number, VibrationData>
  ): Map<number, AggregatedVibrationData> {
    const calculatedBuckets = new Map<number, AggregatedVibrationData>();

    for (let key of groupedBuckets.keys()) {
      const values = groupedBuckets.get(key)!;
      let calculatedBucket: number[];

      if (values.buckets.length > 1) {
        calculatedBucket = this.mergeBuckets(values.buckets);
      } else {
        calculatedBucket = values.buckets.flat();
      }

      calculatedBuckets.set(key, {
        speeds: values.speeds,
        buckets: calculatedBucket
      });
    }

    return calculatedBuckets;
  }

  private assignBucketsToSpeeds(
    speeds: VibrationSpeed[],
    buckets: number[][]
  ): Map<number, VibrationData> {
    const sortedBuckets = new Map<number, VibrationData>();
    const threshold: number = 0.1;

    let currRounded: number = 0;
    let prevRounded: number = 0;

    speeds.forEach((speed: VibrationSpeed, index: number) => {
      currRounded = Math.floor(speed.value * 10) / 10;

      if ((currRounded * 10) % 2 !== 0) {
        currRounded = Math.floor(currRounded * 10) / 10;
      }

      if (index === 0) {
        prevRounded = currRounded;
        sortedBuckets.set(currRounded, {
          speeds: [speed],
          buckets: [buckets[index]]
        });
      } else {
        if (
          (currRounded >= 0 && currRounded >= Math.floor((prevRounded + threshold) * 10) / 10) ||
          (currRounded < 0 && currRounded <= Math.floor((prevRounded - threshold) * 10) / 10)
        ) {
          prevRounded = currRounded;
          sortedBuckets.set(currRounded, {
            speeds: [speed],
            buckets: [buckets[index]]
          });
        } else {
          const currBuckets = sortedBuckets.get(currRounded)!;
          currBuckets.buckets.push(buckets[index]);
          currBuckets.speeds.push(speed);
          sortedBuckets.set(currRounded, currBuckets);
        }
      }
    });
    return sortedBuckets;
  }

  private mergeBuckets(buckets: number[][]): number[] {
    const bucketsLength = buckets[0].length;

    let summedValues = new Array(bucketsLength).fill(0);

    for (let bucket of buckets) {
      for (let i = 0; i < bucketsLength; i++) {
        summedValues[i] += bucket[i];
      }
    }

    return summedValues;
  }

  private findBucketConnector(connectors: DataConnectorDto[]): DataConnectorDto | undefined {
    let bucketName: string;
    connectors.find((conn) => {
      const bucketRegexResult = conn.title.match(this.VIBRATION_BUCKET_REGEX);

      if (bucketRegexResult) {
        bucketName = bucketRegexResult[0];
      }
    });
    return connectors.find((conn) => conn.title.includes(`${bucketName}`));
  }

  protected updateChartData(): void {
    this.interpolateProperties();

    const connectors = this.dataAccessor.getConnectorsByRole(Roles.HeatmapValue.name);

    const bucketConnector = this.findBucketConnector(connectors);
    const limitsConnector = connectors.find((conn) =>
      conn.title.includes(`${this.VIBRATION_TAG}_LIMITS`)
    );
    const speedsConnector = connectors.find((conn) =>
      conn.title.includes(`${this.VIBRATION_TAG}_SPEED`)
    );

    if (!bucketConnector || !limitsConnector || !speedsConnector) {
      return;
    }

    if (
      bucketConnector.dataPoints &&
      limitsConnector.dataPoints &&
      limitsConnector.dataPoints.length > 0 &&
      speedsConnector.dataPoints
    ) {
      this.resetChartData();

      const limitPoints: number[] = limitsConnector.dataPoints[0].y; // xAxis
      const speedPoints: VibrationSpeed[] = speedsConnector.dataPoints.map((dp) => {
        return {
          timestamp: dp.x as Date,
          value: Math.abs(dp.y)
        };
      }); // yAxis
      const bucketPoints: number[][] = bucketConnector.dataPoints.map((dp) => dp.y);

      const { speeds, buckets } = this.sortBucketsAndSpeeds(speedPoints, bucketPoints);

      const groupedBuckets = this.assignBucketsToSpeeds(speeds, buckets);
      const calculatedBuckets = this.calculateBucketsWithCommonSpeed(groupedBuckets);

      this.aggregatedVibrationData = calculatedBuckets;
      this.negativeValues =
        speedsConnector.dataPoints.length > 0 && speedsConnector.dataPoints[0].y < 0;
      this.series = this.getHeatmapTiles(limitPoints, calculatedBuckets);
    } else {
      this.series = [];
    }
  }

  private resetChartData(): void {
    this.shouldShowHistograms = false;
    this.shouldShowClickMessage = true;
    if (isDefined(this.chartObject)) {
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }

  private interpolateProperties(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<VibrationChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.viewConfig = interpolatedProperties.viewConfig;
  }

  protected setChartOptions(): void {
    this.setHeatmapOptions();
  }

  private setHeatmapOptions() {
    const component = this;

    const polePassColor = this.view.polePass;
    const polePass3Color = this.view.polePass3;
    const polePass6Color = this.view.polePass6;
    const polePass9Color = this.view.polePass9;
    const polePass12Color = this.view.polePass12;
    const polePass15Color = this.view.polePass15;
    const polePassThickness = this.view.polePassThickness;
    const polesOffset = this.HEATMAP_X_AXIS_MAX * 0.85;
    (this.chartOptions as Highcharts.Options) = {
      boost: { enabled: false },
      chart: {
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
        height: component.runtimeSize.heightInPx,
        events: {
          redraw: function (event) {
            let chart = this as VibrationChart;
            const points = chart.series[0].data;

            const XCoordinates = component.top6BottomPoints.map((point) => {
              return points.find(({ options }) => 
                options.x === point[0] && 
                options.y === point[1] && 
                options.value === point[2]
              );
            })
            .map((point) => point?.plotX)
            .filter((plotX) => plotX !== undefined)
            .sort((a, b) => a - b);
            
            component.top6BottomXCoordinates = XCoordinates;
          },
          load: function (): void {
            let chart = this as VibrationChart;

            chart.renderer.text('Pole pass', chart.plotLeft, chart.plotHeight + 110)
              .attr({ fill: polePassColor })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();

            chart.renderer.text('Pole pass 3', chart.plotLeft + 80, chart.plotHeight + 110)
              .attr({ fill: polePass3Color })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();
            
            chart.renderer.text('Pole pass 6', chart.plotLeft + 160, chart.plotHeight + 110)
              .attr({ fill: polePass6Color })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();
            
            chart.renderer.text('Pole pass 9', chart.plotLeft + 240, chart.plotHeight + 110)
              .attr({ fill: polePass9Color })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();

            chart.renderer.text('Pole pass 12', chart.plotLeft + 320, chart.plotHeight + 110)
              .attr({ fill: polePass12Color })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();

            chart.renderer.text('Pole pass 15', chart.plotLeft + 400, chart.plotHeight + 110)
              .attr({ fill: polePass15Color })
              .css({ fontSize: '0.8rem', fontWeight: 'bold' })
              .add();
          },
          render: function (): void {
            let chart = this as VibrationChart;

            if (chart.polePass) {
              chart.polePass.destroy();
              chart.polePass = undefined;
            }

            if (chart.polePass3) {
              chart.polePass3.destroy();
              chart.polePass3 = undefined;
            }

            if (chart.polePass6) {
              chart.polePass6.destroy();
              chart.polePass6 = undefined;
            }
            if (chart.polePass9) {
              chart.polePass9.destroy();
              chart.polePass9 = undefined;
            }
            if (chart.polePass12) {
              chart.polePass12.destroy();
              chart.polePass12 = undefined;
            }
            if (chart.polePass15) {
              chart.polePass15.destroy();
              chart.polePass15 = undefined;
            }

            const lines: any[] = [];
            const offset = 40;

            component.top6BottomXCoordinates.forEach((x: number, index: number) => {
              const pointX1 = chart.plotLeft + offset + (index * (offset + index + offset));
              const pointY1 = chart.plotTop;

              const pointX2 = chart.plotLeft + x;
              const pointY2 = chart.plotTop + chart.plotHeight;

              const linePath = ['M', pointX1, pointY1, 'L', pointX2, pointY2] as any;
              lines.push(linePath);
            });

            chart.polePass = chart.renderer.path(lines[0])
              .attr({'stroke-width': 2, stroke: polePassColor, zIndex: 10 })
              .add();

            chart.polePass3 = chart.renderer.path(lines[1])
              .attr({'stroke-width': 2, stroke: polePass3Color, zIndex: 10 })
              .add();

            chart.polePass6 = chart.renderer.path(lines[2])
              .attr({'stroke-width': 2, stroke: polePass6Color, zIndex: 10 })
              .add();

            chart.polePass9 = chart.renderer.path(lines[3])
              .attr({'stroke-width': 2, stroke: polePass9Color, zIndex: 10 })
              .add();

            chart.polePass12 = chart.renderer.path(lines[4])
              .attr({'stroke-width': 2, stroke: polePass12Color, zIndex: 10 })
              .add();

            chart.polePass15 = chart.renderer.path(lines[5])
              .attr({'stroke-width': 2, stroke: polePass15Color, zIndex: 10 })
              .add();
          }
        }
      },

      title: {
        text: undefined
      },

      xAxis: {
        id: PRIMARY_X_AXIS_ID,
        title: {
          text: "Frequency (Hz)"
        },
        type: this.viewConfig.xPrecision ? "category" : undefined,
        min: this.HEATMAP_X_AXIS_MIN,
        max: this.HEATMAP_X_AXIS_MAX
      },

      yAxis: {
        reversed: true,
        id: PRIMARY_Y_AXIS_ID,
        title: {
          text: "Rotation Speed (RPM)" + (this.negativeValues ? " (negative values)" : "")
        },
        type: this.viewConfig.yPrecision ? "category" : undefined,
        labels: {
          formatter: function () {
            return (+this.value / 10).toString();
          }
        }
      },

      colorAxis: {
        reversed: false,
        min: 0,
        stops: [
          [0.0, this.viewConfig.lowestColor],
          [0.02, this.viewConfig.lowerColor],
          [0.1, this.viewConfig.mediumColor],
          [0.4, this.viewConfig.higherColor],
          [1.0, this.viewConfig.highestColor]
        ]
      },

      legend: {
        align: "right",
        layout: "vertical",
        verticalAlign: "top",
        margin: 0,
        padding: 20,
        y: 10,
        symbolHeight: 260,
        title: {
          text: "Acceleration"
        }
      },

      credits: {
        enabled: false
      },

      series: [
        {
          name: "Vibration",
          type: "heatmap",
          data: this.series,
          turboThreshold: DATA_POINTS_TO_REQUEST,
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
              click: function (event: Highcharts.PointClickEventObject): void {
                component.handleOnCellClick(event.point.options);
              }
            }
          }
        }
      ]
    };
  }

  private handleOnCellClick(point: Highcharts.PointOptionsObject): void {
    if (!this.aggregatedVibrationData) {
      throw new Error("AggregatedVibrationData not defined!");
    }

    const key = (point.y ?? 0) / 10;

    const cellData = this.aggregatedVibrationData.get(key);

    if (!cellData) {
      throw new Error("Failed to find requested aggregated data!");
    }

    const dates = cellData.speeds.map((speed) => {
      const ms = 1000 * 60;
      const roundedDate = new Date(Math.round(speed.timestamp.getTime() / ms) * ms);
      const from = new Date(roundedDate);
      from.setMinutes(from.getMinutes() - 1);
      const to = new Date(roundedDate);
      to.setMinutes(to.getMinutes() + 1);
      return {
        speed: speed.value,
        from,
        to
      };
    });

    this.setHistogramOptions();

    this.shouldShowClickMessage = false;
    this.shouldShowHistograms = false;
    this.loadingSpinner = refreshLoadingIndicator(
      true,
      this.loadingSpinner,
      this.spinnerContainer!
    );

    this.setHistogramData(dates);
  }

  private setHistogramOptions() {
    const component = this;
    const histogramConnectors = this.dataAccessor.getConnectorsByRole(Roles.HistogramValue.name);

    const options: Highcharts.Options[] = histogramConnectors.map((hc) => {
      return {
        boost: { enabled: false },

        chart: {
          type: "histogram",
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          events: {},
          height: "98%"
        },

        title: {
          text: undefined
        },

        credits: {
          enabled: false
        }
      } as Highcharts.Options;
    });

    this.histogramChartObjects = [];

    options.forEach((op) => {
      op.chart!.events!.load = function () {
        component.histogramChartObjects.push(this);
      };
    });

    this.histogramChartOptions = options;
  }

  private setHistogramData(dates: VibrationDateRange[]) {
    this.sendQuery(dates).subscribe((response) => {
      if (!isDefined(this.histogramChartObjects)) {
        return;
      }

      const groupedResponse = this.groupResponseBySeries(response);
      const histogramConnectors = this.dataAccessor.getConnectorsByRole(Roles.HistogramValue.name);

      const series: SeriesOptionsType[][] = histogramConnectors.map((connector) => {
        if (!groupedResponse[connector.id]) {
          return [];
        }
        const data: number[] = groupedResponse[connector.id].flatMap((r) =>
          r.DataPoints.map((dp) => dp.value as number)
        );

        return [
          {
            name: connector.title,
            type: "histogram",
            id: connector.id + "/h",
            baseSeries: connector.id,
            color: this.dataConnectorViewSelector.getById(getConnectorViewId(connector.id))?.color
          },
          {
            id: connector.id,
            name: connector.title,
            type: "scatter",
            data,
            visible: false,
            showInLegend: false
          }
        ] as SeriesOptionsType[];
      });

      this.loadingSpinner = refreshLoadingIndicator(
        false,
        this.loadingSpinner,
        this.spinnerContainer!
      );
      this.shouldShowHistograms = true;

      this.histogramChartObjects.forEach((ob, i) =>
        ob.update(
          {
            series: series[i]
          },
          true,
          true,
          true
        )
      );
    });
  }

  public sendQuery(dates: VibrationDateRange[]): Observable<TrendResultDto[]> {
    const heatmapConnectors = this.dataAccessor.getConnectorsByRole(Roles.HeatmapValue.name);
    const bucketConnector = this.findBucketConnector(heatmapConnectors);
    if (!isDefined(bucketConnector)) {
      return of([]);
    }
    const bucketDataSource = bucketConnector.dataSource as SignalDataSourceDto;
    //TODO create a service to deal with signalId
    const bucketSignalId = (bucketDataSource.signal.id as string).split(".");

    const plant = bucketSignalId[1];
    const rootEquipment = bucketSignalId[2];

    const histogramConnectors = this.dataAccessor.getConnectorsByRole(Roles.HistogramValue.name);

    const Clients: ClientDto[] = histogramConnectors.flatMap((connector) => {
      const dataSource = connector.dataSource as SignalDataSourceDto;
      const signalId = dataSource.signal.id?.toString();

      if (!signalId) {
        return [];
      }

      return dates.map((date, i) => {
        return {
          Anomalies: false,
          ClientId: connector.id + "/" + i,
          FileType: signalId.split(".")[0],
          Forecast: false,
          LastValue: false,
          Signal: signalId,
          From: date.from,
          To: date.to,
          DataPointCounts: 100
        };
      });
    });

    const query: Query<ClientDto> = {
      plant,
      rootEquipment,
      body: {
        Frequency: this.HISTOGRAM_SIGNAL_PTYPE,
        Clients
      }
    };
    return this.queryService.sendQuery(query);
  }

  private groupResponseBySeries(responses: TrendResultDto[]): Dictionary<TrendResultDto[]> {
    const groupedResponses: Dictionary<TrendResultDto[]> = {};

    responses.forEach((response) => {
      const key = response.ClientId.split("/")[0];
      if (!groupedResponses[key]) {
        groupedResponses[key] = [];
      }
      groupedResponses[key].push(response);
    });

    return groupedResponses;
  }
}

