import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { map, mergeAll } from "rxjs/operators";
import {
  DataColumnType,
  DataConnectorDto,
  DataTableResponse,
  Dictionary,
  Filter,
  GenericDataSourceDescriptor,
  GenericDataSourceDto,
  HttpService,
  QueryParamsResolverService,
  WebServicesConfiguration
} from "ui-core";
import { EMPTY_COMBO_BOX_ENTRY } from "../../../../../ui-core/src/lib/meta/helpers/enum-combobox.helper";
import { ApiResponse } from "../../models/api/api-response";
import { GenericClientDto, QueryDto } from "../../models/api/query";
import { AzureQueryStringService } from "./azure-query-string.service";
import { GenericQueriesStrategy } from "./generic-query-strategies/generic-queries-strategy";
import { PlantTimeConverter } from "./plant-time.converter";

@Injectable()
export class GenericQueriesService {
  constructor(
    private queryStringService: AzureQueryStringService,
    private httpService: HttpService,
    private apiConfig: WebServicesConfiguration,
    private timeConverter: PlantTimeConverter,
    private queryParamsResolver: QueryParamsResolverService
  ) {}

  getData(
    filters: Dictionary<Filter>,
    genericQueries: Dictionary<GenericDataSourceDto>,
    queryType: string,
    mapDataTablesToConnectors: (
      response: DataTableResponse,
      queries: Dictionary<GenericDataSourceDto>
    ) => Dictionary<DataConnectorDto[]>
  ): Observable<Dictionary<DataConnectorDto[]>> {
    const strategy = this.chooseProcessingStrategy(queryType);
    var requests = strategy.prepareRequests(filters, genericQueries);

    const requestObservables = requests.map(request => this.sendRequest(request, queryType));  
      
    return forkJoin(requestObservables).pipe(
      mergeAll(),
      map((response: ApiResponse[]) => strategy.processResponse(response, genericQueries)),
      map((response: DataTableResponse) => mapDataTablesToConnectors(response, genericQueries)),
      map((dataConnectors: Dictionary<DataConnectorDto[]>) => {
        return strategy.filterConnectorsFromGenericQuery(dataConnectors, genericQueries);
      })
    );
  }
  chooseProcessingStrategy(queryType: string): GenericQueriesStrategy {
    switch (queryType) {
      default:
        return new GenericQueriesStrategy(
          this.queryStringService,
          this.timeConverter,
          this.queryParamsResolver
        );
    }
  }

  sendRequest(query: QueryDto<GenericClientDto>, queryType: string): Observable<ApiResponse[]> {
    const rootEquipment: string = this.queryStringService.getMotorName() || "all";
    let aggregated = "";

    if (query.Clients[0].Group1) {
      aggregated = "/aggregated";
    }

    return this.httpService.post({
      url: `${this.apiConfig.logDataServiceUrl}/customers/${this.queryStringService.getPlantName()}/motors/${rootEquipment}${queryType}${aggregated}`,
      body: query,
      failureDefault: []
    });
  }

  static getGenericDataSourceDescriptors(): Observable<GenericDataSourceDescriptor[]> {
    const genericDataSourceDescriptors: GenericDataSourceDescriptor[] = [
      {
        entity: EMPTY_COMBO_BOX_ENTRY,
        columns: [],
        isValid: true
      },
      {
        entity: "/pec-events",
        columns: [
          { name: "Timestamp", type: DataColumnType.Date },
          { name: "Message", type: DataColumnType.String },
          { name: "Source", type: DataColumnType.String },
          { name: "Category", type: DataColumnType.String },
          { name: "CategoryIcon", type: DataColumnType.Unknown },
          { name: "Enabled", type: DataColumnType.Number },
          { name: "EnabledIcon", type: DataColumnType.Unknown },
          { name: "Active", type: DataColumnType.String },
          { name: "ActiveIcon", type: DataColumnType.Unknown },
          { name: "Ack", type: DataColumnType.String },
          { name: "AckIcon", type: DataColumnType.Unknown },
          { name: "EquipmentPath", type: DataColumnType.String },
          { name: "Subsystem", type: DataColumnType.String },
          { name: "CategoryNorm", type: DataColumnType.String },
          { name: "Controller", type: DataColumnType.String },
          { name: "Severity", type: DataColumnType.Number },
          { name: "EventId", type: DataColumnType.String },
          { name: "SeverityGroup", type: DataColumnType.String },
          { name: "ConditionClassName", type: DataColumnType.String },
          { name: "GroupNumber", type: DataColumnType.Number },
          { name: "FailureReason", type: DataColumnType.Unknown },
          { name: "CorrectiveActionsAsList", type: DataColumnType.Unknown },
          { name: "EffectsAsList", type: DataColumnType.Unknown },
          { name: "PossibleCausesAsList", type: DataColumnType.Unknown },
          { name: "RemarksAsList", type: DataColumnType.Unknown }
        ],
        isValid: true
      },
      {
        entity: "/initial-trips",
        columns: [
          { name: "StartDateTime", type: DataColumnType.Date },
          { name: "EndDateTime", type: DataColumnType.Date },
          { name: "Message", type: DataColumnType.String },
          { name: "Source", type: DataColumnType.String },
          { name: "Duration", type: DataColumnType.String },
          { name: "TotalHours", type: DataColumnType.Number },
          { name: "TotalDays", type: DataColumnType.Number }
        ],
        isValid: true
      }
    ];

    return of(genericDataSourceDescriptors);
  }
}
