import { v4 as uuid } from "uuid";
import { CommonDataPointPropertyNames } from "../../data-connectivity/models/common-data-properties";
import { DataConnectorRole } from "../../data-connectivity/models/data-connector-role";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurationCategory,
  DynamicDefaultFromFunction,
  DynamicDefaultFromMetadata,
  DynamicDefaultFromValue,
  Serializable
} from "../../meta/decorators";
import { GroupIndividualProperties } from "../../meta/decorators/group-individual-properties.decorator";
import { Placeholder } from "../../meta/decorators/placeholder.decorator";
import { EditorType } from "../../meta/models/editor-type";
import {
  FIRST_SUBGROUP_INDEX,
  GroupPropertiesInfo,
  SECOND_SUBGROUP_INDEX
} from "../../meta/models/groupable-properties";
import { PropertyCategory } from "../../meta/models/property-category";
import { validateNumberOrInterpolatedString } from "../../property-sheet/helpers/number-validation.helper";
import {
  PropertyDecoratorFunction,
  applyPropertyDecorators
} from "../../ts-utils/helpers/decorator.helper";
import { nameof } from "../../ts-utils/helpers/name-of";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { WIDGET_PROPERTIES__MIN_MAX } from "./help-constants";
import { LimitsConfig } from "./i-view-config/i-base-display-config";

export interface MinMaxConfigDto {
  min: Maybe<string>;
  max: Maybe<string>;
}

function configureMinOrMax(
  prototype: Object,
  key: string,
  displayName: string,
  roleToTakeValuesFrom: DataConnectorRole,
  propertyName: string,
  defaultValue: Maybe<number>,
  isHidden: boolean,
  groupPropertiesInfo: Record<string, GroupPropertiesInfo>
): void {
  const configurablePropertyDecorators: PropertyDecoratorFunction[] = [
    ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General),
    Configurable({
      displayName: displayName,
      editorType: EditorType.TextBox,
      validationFunction: validateNumberOrInterpolatedString,
      userHelp: WIDGET_PROPERTIES__MIN_MAX
    }),
    Serializable(),
    AllowInterpolation(),
    Placeholder({
      text: LOCALIZATION_DICTIONARY.propertySheet.DynamicDefaultsPlaceholder,
      combineWithRuntimeValue: true
    })
  ];

  applyPropertyDecorators(
    prototype,
    key,
    ...[
      ...(isHidden ? [] : configurablePropertyDecorators),
      DynamicDefaultFromMetadata(roleToTakeValuesFrom.name, propertyName, -5, true, true)
    ]
  );

  if (isDefined(defaultValue)) {
    applyPropertyDecorators(
      prototype,
      key,
      DynamicDefaultFromValue(defaultValue.toString(), CALCULATED_MINMAX_PRIORITY - 1)
    );
  }

  applyPropertyDecorators(prototype, key, GroupIndividualProperties(groupPropertiesInfo[key]));
}

// this must be lower than limits priorities, as calculation deponds on limits.
const CALCULATED_MINMAX_PRIORITY = -10;

export function configureMinMax<T extends MinMaxConfigDto>(
  viewConfigType: {
    new (...args: any[]): T;
  },
  roleToTakeValuesFrom: DataConnectorRole,
  minDefault: Maybe<number>,
  maxDefault: Maybe<number>,
  minMaxFromLimits: number,
  isHidden: boolean = false
): void {
  const prototype = viewConfigType.prototype;
  const groupPropertiesInfo: Record<string, GroupPropertiesInfo> = getGroupProperties();

  configureMinOrMax(
    prototype,
    nameof<MinMaxConfigDto>("min"),
    LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    roleToTakeValuesFrom,
    CommonDataPointPropertyNames.min,
    minDefault,
    isHidden,
    groupPropertiesInfo
  );

  if (minMaxFromLimits !== 0) {
    applyPropertyDecorators(
      prototype,
      nameof<MinMaxConfigDto>("min"),
      DynamicDefaultFromFunction((view) => {
        const [min, max] = getOuterLimits(view);
        if (isDefined(min) && isDefined(max)) {
          return min - minMaxFromLimits * (max - min);
        }
        return null;
      }, CALCULATED_MINMAX_PRIORITY)
    );
    applyPropertyDecorators(
      prototype,
      nameof<MinMaxConfigDto>("max"),
      DynamicDefaultFromFunction((view) => {
        const [min, max] = getOuterLimits(view);
        if (isDefined(min) && isDefined(max)) {
          return max + minMaxFromLimits * (max - min);
        }
        return null;
      }, CALCULATED_MINMAX_PRIORITY)
    );
  }
  configureMinOrMax(
    prototype,
    nameof<MinMaxConfigDto>("max"),
    LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    roleToTakeValuesFrom,
    CommonDataPointPropertyNames.max,
    maxDefault,
    isHidden,
    groupPropertiesInfo
  );
}

function getOuterLimits(viewConfig: LimitsConfig): Maybe<number>[] {
  const limitsDto = viewConfig.limits;
  if (isDefined(limitsDto)) {
    const lower = [limitsDto.extremeLow, limitsDto.veryLow, limitsDto.low].find(isDefined);
    const upper = [limitsDto.extremeHigh, limitsDto.veryHigh, limitsDto.high].find(isDefined);
    return [lower, upper];
  }
  return [null, null];
}

function getGroupProperties(): Record<string, GroupPropertiesInfo> {
  const groupId = uuid();
  return {
    min: { subgroupIndex: FIRST_SUBGROUP_INDEX, groupId },
    max: { subgroupIndex: SECOND_SUBGROUP_INDEX, groupId }
  };
}
